<template>
  <b-card class="card-transaction" no-body>
    <b-card-header>
      <b-card-title class="mx-auto">Resumo Financeiro</b-card-title>
    </b-card-header>

    <b-card-body>
      
      <div class="transaction-item">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-success">
              <feather-icon size="28" icon="DollarSignIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">Parcela(s) Paga(s)</h6>
            <div class="font-weight-bolder" id="parcelas">
              {{ getSelectedContract.parcelas_mensais_pagas }}/{{getSelectedContract.parcelas_mensais}}
            </div>
          </b-media-body>
        </b-media>
      </div>

      <div class="transaction-item" v-if="getSelectedContract.proximo_reajuste">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-info">
              <feather-icon size="28" icon="TrendingUpIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">Próximo Reajuste</h6>
            <div class="font-weight-bolder" id="proximo_reajuste">
              {{ getSelectedContract.proximo_reajuste }}
            </div>
          </b-media-body>
        </b-media>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-warning">
              <feather-icon size="28" icon="InfoIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">Próximo Vencimento</h6>
            <div class="font-weight-bolder" id="proximo_vencimento">{{ formatDate(getSelectedContract.proximo_vencimento) }}</div>
          </b-media-body>
        </b-media>
      </div>

      <div class="d-flex flex-wrap justify-content-around mt-3" id="button">
        <b-button
          v-if="getSelectedContract.fatura_link"
          class="font-small-3"
          block
          variant="primary"
          @click="$router.push({name: 'document-viewer', params: { data: getSelectedContract.fatura_link, documentName: 'Fatura do Mês'}})"
          target="_blank"
          >Fatura do Mês</b-button
        >
        <b-button
          v-else-if="getSelectedContract.inadimplente == 'Sim'"
          class="font-small-3"
          block
          @click="$router.push('financiamento')"
          variant="danger"
          >
          Pagar parcelas atrasadas
        </b-button>
        <b-button
          v-else
          class="font-small-3"
          block
          @click="$router.push('financiamento')"
          variant="primary"
          >
          Ver Parcelas
          </b-button
        >
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BButton,
} from "bootstrap-vue"
import store from "@/store"
import formatValue from "@/utils/formatValue"

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BButton,
  },
	mixins: [formatValue],
  computed: {
    getSelectedContract() {
      return store.getters["contracts/getSelectedContract"]
    },
  },
}
</script>