<template>
	<b-row :class="{ 'match-haeight': selectedContract.inadimplente_iptu == 'Sim' }">
		<b-col lg="12">
			<select-contract />
		</b-col>
		<template v-if="!isLoading && selectedContract">
			<b-col class="d-md-none" xl="5" md="5">
				<contractDebitosMunicipais
					v-if="
						selectedContract.valor_debitos_municipais > 0 &&
							selectedContract.aquisicao_escritura === 'Não'
					"
					id="debitos-municipais-info"
				/>
				<card-medal v-if="selectedContract.situacao == 50" id="card-medal" />
			</b-col>

			<b-col xl="7" md="7">
				<contract-info />
			</b-col>

			<b-col
				xl="5"
				md="5"
				v-if="
					selectedContract.aquisicao_escritura === 'Não' && selectedContract.situacao != 50
				"
			>
				<contractDebitosMunicipais
					class="d-none d-md-block"
					v-if="selectedContract.valor_debitos_municipais > 0"
					id="debitos-municipais-info"
				/>
				<contract-financeiro id="financeiro-info" />
			</b-col>

			<b-col class="d-none d-md-block " xl="5" md="5" v-if="selectedContract.situacao == 50">
				<card-medal id="card-medal" />
			</b-col>
		</template>
		<template v-else>
			<b-spinner class="mx-auto my-5" />
		</template>
	</b-row>
</template>

<script>
import { BRow, BCol, BSpinner } from 'bootstrap-vue'
import SelectContract from '@/views/components/selectContract.vue'
import contractInfo from '@/views/components/contractInfo.vue'
import contractFinanceiro from '@/views/components/contractFinanceiro.vue'
import contractDebitosMunicipais from '@/views/components/contractDebitosMuncipais.vue'
import cardMedal from '@/views/components/CardMedal.vue'
import store from '@/store'

export default {
	components: {
		BRow,
		BCol,
		SelectContract,
		contractInfo,
		cardMedal,
		contractFinanceiro,
		contractDebitosMunicipais,
		BSpinner,
	},

	data() {
		return {
			isLoading: false,
			contract: null,
		}
	},

	computed: {
		selectedContract() {
			return store.getters['contracts/getSelectedContract']
		},
	},

	watch: {
		selectedContract() {
			this.isLoading = true
			setTimeout(() => (this.isLoading = false), 400)
		},
	},
}
</script>
