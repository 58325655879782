<template>
	<b-card class="card-transaction" no-body>
		<b-card-header>
			<b-card-title class="mx-auto">Débitos Municipais</b-card-title>
		</b-card-header>

		<b-card-body>
			<div class="transaction-item">
				<b-media no-body>
					<b-media-aside>
						<b-avatar rounded size="42" variant="light-danger">
							<feather-icon size="28" icon="DollarSignIcon" />
						</b-avatar>
					</b-media-aside>
					<b-media-body>
						<h3 class="p-0 m-auto">
							{{ formatPriceTable(selectedContract.valor_debitos_municipais) }}
						</h3>
						<small v-if="selectedContract.data_debitos_municipais">
							<b
								>Atualizado em
								{{ formatDate(selectedContract.data_debitos_municipais) }}</b
							></small
						>
					</b-media-body>
				</b-media>
			</div>
		</b-card-body>
	</b-card>
</template>

<script>
import {
	BCard,
	BCardTitle,
	BMediaBody,
	BMediaAside,
	BAvatar,
	BCardBody,
	BCardHeader,
	BMedia,
} from 'bootstrap-vue'
import formatValue from '@/utils/formatValue'
import store from '@/store'

export default {
	components: {
		BCard,
		BCardTitle,
		BMediaBody,
		BCardBody,
		BMediaAside,
		BAvatar,
		BCardHeader,
		BMedia,
	},
	mixins: [formatValue],
	computed: {
		selectedContract() {
			return store.getters['contracts/getSelectedContract']
		},
	},
}
</script>
