<template>
	<b-card no-body class="card-developer-meetup" v-if="getSelectedContract">
		<div class="bg-light-primary rounded-top text-center py-1">
			<b-img :src="selectedEmpreendimentoImg" alt="Empreendimento Image" height="120" />
		</div>
		<b-card-body class="pb-0">
			<div class="meetup-header flex-wrap d-flex mt-1 mb-0">
				<b-card-title class="col-12 text-center mb-1">
					<h3 class="font-weight-light" id="empreendimento_fantasia">
						{{ getSelectedContract.empreendimento_fantasia }}
					</h3>
					<h5 class="font-weight-normal" id="empreendimento_municipio_uf">
						({{ getSelectedContract.empreendimento_municipio }} -
						{{ getSelectedContract.empreendimento_uf }})
					</h5>
				</b-card-title>

				<b-card-title class="col-12 text-center mb-0">
					<h4 class="mb-0 text-center font-weight-bold">
						<span id="quadra"> Quadra {{ getSelectedContract.quadra }} </span>
						-
						<span id="lote"> Lote {{ getSelectedContract.lote }} </span>
					</h4>
					<b-badge
						class="mx-auto my-2 display-1 p-50"
						pill
						:variant="situacaoVariant"
						id="situacao_contrato"
					>
						<template v-if="getSelectedContract.inadimplente == 'Sim'">
							Inadimplente
						</template>
						<template v-else>
							{{ getSelectedContract.situacao_descricao }}
						</template>
					</b-badge>
				</b-card-title>
			</div>

			<div class="d-flex justify-content-around flex-wrap align-items-center">
				<template v-if="getSelectedContract.aquisicao_escritura === 'Não'">
					<div class="mx-1 my-1 p-0 m-0">
						<h6 class="text-center">Data Venda</h6>
						<h6 class="font-weight-bolder text-center info-titulo" id="data_venda">
							{{ formatDate(getSelectedContract.data_venda) }}
						</h6>
					</div>

					<div class="mx-1 my-1 p-0 m-0">
						<h6 class="text-center">Valor Contrato</h6>
						<h6 class="font-weight-bolder text-center info-titulo" id="valor_venda">
							{{ formatPriceTable(getSelectedContract.valor_venda) }}
						</h6>
					</div>
				</template>
				<div class="mx-1 my-1 p-0 m-0">
					<h6 class="text-center">Área (m²)</h6>
					<h6 class="font-weight-bolder text-center info-titulo" id="lote_area">
						{{ formatNumber(getSelectedContract.lote_area) }}
					</h6>
				</div>

				<div class="mx-1 my-1 p-0 m-0">
					<h6 class="text-center">Matrícula</h6>
					<h6 class="font-weight-bolder text-center info-titulo" id="lote_matricula">
						<template v-if="getSelectedContract.lote_matricula">
							{{ getSelectedContract.lote_matricula }}
						</template>
						<template v-else> - </template>
					</h6>
				</div>

				<div class="mx-1 my-1 p-0 m-0">
					<h6 class="d-flex align-items-center flex-wrap text-center">
						BIC
						<b-button
							class="p-0 m-0"
							variant="link"
							v-b-tooltip.hover="'Número do cadastro do lote na Prefeitura Municipal.'"
						>
							<feather-icon icon="HelpCircleIcon" size="14" style="margin-left: 5px" />
						</b-button>
					</h6>
					<h6 class="font-weight-bolder text-center info-titulo" id="lote_bic">
						<template v-if="getSelectedContract.lote_bic">
							{{ getSelectedContract.lote_bic }}
						</template>
						<template v-else> - </template>
					</h6>
				</div>
			</div>
		</b-card-body>

		<b-card-body class="pt-0" v-if="alerts.length > 0">
			<hr />
			<div class="messages ml-1 mt-2" id="alertsList">
				<div
					class="message-item d-flex align-items-center mt-50"
					v-for="alert in alerts"
					:key="alert.mensagem"
				>
					<feather-icon
						icon="CircleIcon"
						size="5"
						class="mr-50 bg-warning bg-darken-2 rounded-circle"
					/>
					<template v-if="alert.tipo == 1">
						<a href="/financiamento"
							><span class="font-weight-bold">{{ alert.mensagem }}</span></a
						>
					</template>
					<template v-else>
						<span class="font-weight-bold">{{ alert.mensagem }}</span>
					</template>
				</div>
			</div>
		</b-card-body>
	</b-card>
</template>

<script>
import store from '@/store'
import { getContractAlerts } from '@/services/contract.js'
import formatValue from '@/utils/formatValue.js'
import {
	BCard,
	BImg,
	BCardBody,
	BBadge,
	BCardTitle,
	VBTooltip,
	BButton,
} from 'bootstrap-vue'

/* eslint-disable global-require */
export default {
	components: {
		BCard,
		BImg,
		BBadge,
		BCardBody,
		BCardTitle,
		BButton,
	},
	mixins: [formatValue],
	directives: {
		'b-tooltip': VBTooltip,
	},
	data() {
		return {
			paymentInfo: {},
			alerts: [],
		}
	},

	methods: {
		getContractAlerts: async function() {
			let { data } = await getContractAlerts(this.$apolloProvider.defaultClient)
			this.alerts = data
		},
	},

	watch: {
		getSelectedContract: {
			handler() {
				this.getContractAlerts()
			},
		},
	},

	computed: {
		selectedEmpreendimentoImg() {
			if (store.getters['contracts/getSelectedContract'].empreendimento) {
				return require('@/assets/images/logotipos_empreendimentos/empreendimento-logo-' +
					store.getters['contracts/getSelectedContract'].empreendimento +
					'.png')
			} else {
				return require('@/assets/images/logotipos_empreendimentos/empreendimento-logo-24.png')
			}
		},

		getSelectedContract() {
			this.getContractAlerts()
			return store.getters['contracts/getSelectedContract']
		},

		situacaoVariant() {
			if (store.getters['contracts/getSelectedContract'].inadimplente == 'Sim') {
				return 'danger'
			} else {
				if (store.getters['contracts/getSelectedContract'].situacao == 50) {
					return 'success'
				} else if (store.getters['contracts/getSelectedContract'].situacao == 40) {
					return 'primary'
				} else {
					return 'danger'
				}
			}
		},
	},
}
</script>

<style scoped>
.info-titulo {
	white-space: nowrap;
}
</style>
