<template>
	<b-card class="card-congratulation-medal py-2" id="medal-msg">
		<b-card-title>Parabéns, {{ loggedCustomer.first_name }}! 🎉 </b-card-title>
		<template v-if="getSelectedContract.data_quitacao">
			<b-card-text class="font-small-4"> seu lote foi quitado em: </b-card-text>
			<span class="bg-primary font-weight-bolder rounded text-white" style="padding: 7px">
				{{ formatDate(getSelectedContract.data_quitacao) }}
			</span>
		</template>

		<template v-else>
			<b-card-text class="font-small-4"> Seu lote está quitado. </b-card-text>
		</template>
		<b-img
			:src="require('@/assets/images/illustration/badge.svg')"
			class="congratulation-medal"
			alt="Medal Pic"
		/>
	</b-card>
</template>

<script>
import { BCard, BCardText, BImg, BCardTitle } from 'bootstrap-vue'
import store from '@/store'
import formatValue from '@/utils/formatValue'

export default {
	components: {
		BCard,
		BCardText,
		BCardTitle,
		BImg,
	},

	mixins: [formatValue],

	computed: {
		loggedCustomer() {
			if (!store.state.customer.loggedCustomer.name) {
				return {
					name: '',
					email: '',
				}
			} else {
				return store.state.customer.loggedCustomer
			}
		},

		getSelectedContract() {
			return store.state.contracts.selectedContract
		},
	},
}
</script>
